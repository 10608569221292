.page-header-basic {

	position: relative;
	@include modulePaddingTop;

	&--has-background {
		padding-bottom: rem(80);
		background: $gradient-gray;
		@include breakpoint(medium) {
			padding-bottom: rem(120);
		}
		@include breakpoint(large) {
			padding-bottom: rem(150);
		}
	}

	&__wrapper {
		position: relative;
		@include secondary-grid-row;
		justify-content: center;
		z-index: 2;
	}

	&__heading {
		text-align: center;
		@include secondary-grid-column(24);
		@include breakpoint(medium) {
			@include secondary-grid-column(20);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(16);
		}

		span {
			display: block;
			margin-bottom: rem(5);
		}

		h1 {
			margin: 0;
			hyphens: auto;;
		}

	}

	&__text {

		text-align: center;
		padding-top: rem(20);
		@include largerFontSize;
		@include secondary-grid-column(22);
		@include breakpoint(medium) {
			padding-top: rem(25);
			@include secondary-grid-column(18);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(14);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(10);
		}

		p:last-child {
			margin: 0;
		}

	}

	&__button-container {
		padding-top: rem(35);
		@include secondary-grid-column(24);
		text-align: center;
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		svg {
			object-fit: contain;
			object-position: bottom left;
			height: 100%;
		}
	}

}
