.quote {

	@include modulePaddingTop;

	&__wrapper {
		@include secondary-grid-row();
	}
	&__content {
		@include secondary-grid-column(24);
		@include breakpoint(medium) {
			@include secondary-grid-column(20);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(16);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(16);
		}
	}

	&__prefix,
	&__postfix {
		display: block;
	}

	&__postfix {
		text-align: right
	}

	&__main {
		@include h2FontSize;
		padding: rem(20) 0;
		margin: 0;
	}


}
