.popup-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(8, 21, 73, 0.2);
	z-index: 20;
	display: flex;
    align-items: flex-start;
    justify-content: center;

    &__wrapper {
    	position: relative;
    	top: 10%;
    	margin: 0 1rem;

    	&:before, &:after {
			content: '';
			position: absolute;
	    	left: 0;
	    	width: 100%;
	    	height: 1rem;
		}

		&:before {
			top: 0;
			background: linear-gradient(0deg,hsla(0,0%,100%,0), #FFFFFF);
		}

		&:after {
			bottom: 0;
	    	background: linear-gradient(180deg,hsla(0,0%,100%,0), #FFFFFF);
		}
    }

	&__modal {
		display: flex;
		flex-direction: column;
		height: 100%;
		max-height: rem(600);
    	overflow-y: auto;
		max-width: rem(900);
		
		padding: rem(55);
		background: $white;
		box-shadow: 10px 10px 20px rgba(8, 21, 73, 0.1);

		&--country-list {
			height: auto;
    		overflow-y: visible;

    		.popup-overlay__button-wrapper {
    			margin-top: rem(24);
    		}
		}

		@include breakpoint(large) {
			min-width: rem(800);
			margin: 0;
		}
		
		&-title {
			opacity: 0.6;
		}
	}

	&__button-wrapper {
		display: flex;
	    justify-content: flex-end;
	    gap: rem(20);
	    margin-top: auto;
	}

	&__close {
		position: absolute;
		top: rem(20);
		right: rem(20);
	}

	.content-container {
		padding-top: 5rem;

		&__header-wrapper,
		&__header-title,
		.downloads-list__item {
			padding-left: 0;
			padding-right: 0;
		}
	}
}