.section-divider {

	@include modulePaddingTop;

	&__wrapper {
		@include row();
	}
	&__container {
		@include flex-column(12);
	}
	&__border {
		width: 100%;
		height: rem(1);
		background-color: get-color(mediumBlue);
	}
}
