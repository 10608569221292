.key-figures {
	@include modulePaddingTop;

	h2 {
		color: $white;
		margin-bottom: 0;
	}

	&__wrapper {
		@include secondary-grid-row;

	}
	&__container {
		padding-top: rem(80);
		padding-bottom: rem(40);
		margin: auto;

		background-color: get-color(darkBlue);
		color: $white;

		@include secondary-grid-column(24);

		@include breakpoint( medium ) {
			padding-bottom: rem(80);
		}

		@include breakpoint( xlarge ) {
			@include secondary-grid-column(20);
		}
	}
	.key-figures__chart-container {
		margin: auto;
		background-color: get-color(white);

		@include secondary-grid-column(24);

		@include breakpoint( medium ) {
			padding-bottom: rem(80);
		}

		@include breakpoint( xlarge ) {
			@include secondary-grid-column(20);
		}

		.fund-performance-app {
			max-width: none;
			padding-top: 2rem;
		}
	}

	&__heading {
		text-align: center;

		p {
			@include secondary-grid-column(20);
			margin: rem(24) auto 0;

			@include breakpoint( medium ) {
				@include secondary-grid-column(11);
			}
		}
	}

	.fund-performance-app {
		@include secondary-grid-column(22);
		margin: auto;
		padding-top: rem(40);

		@include breakpoint( medium ) {
			padding-top: rem(98);
		}
	}

	&__bottom-container {
		// flex: 0 0 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	    max-width: 100%;
    	text-align: center;
    	margin-top: rem(40);
    	flex-direction: column;

    	@include breakpoint( medium ) {
    		flex-direction: row;
    		margin-top: rem(80);
		}
	}

	.key-numbers {

		&__grid {
			display: grid;
			grid-template-columns: repeat(2,minmax(0,1fr));
			gap: rem(40) rem(25);

			@include breakpoint( medium ) {
				gap: rem(80) rem(110);
			}
		}

		.label {
			padding-bottom: rem(10);
			border-bottom: 1px solid get-color(permafrost);
		}

		.growth {
			font-weight: 300;
			font-size: rem(30);

			@include breakpoint( medium ) {
				font-size: rem(60);
			}

			@include breakpoint( large ) {
				font-size: rem(96);
			}

			svg {
				width: rem(30);
				height: rem(30);
				flex-shrink: 0;

				@include breakpoint( medium ) {
					width: rem(50);
					height: rem(50);
				}

				@include breakpoint( large ) {
					width: rem(80);
					height: rem(80);
				}
			}
		}

		.date-updated {
			text-align: center;
			margin-bottom: rem(20);

			@include breakpoint( medium ) {
				margin-bottom: 0;
			}

			.date {
				color: get-color(permafrost);
				display: block;

				@include breakpoint( medium ) {
					display: inline-block;
				}
			}
		}
	}
}