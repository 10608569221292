.two-column-text {

	@include modulePaddingTop;

	&__wrapper {
		@include secondary-grid-row();
	}
	&__header {
		@include secondary-grid-column(18);
		margin-left: secondary-grid-column(2);
		@include breakpoint(small only) {
			margin-bottom: rem(30);
		}
		@include breakpoint( medium ) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(1);
		}
		@include breakpoint( large ) {
			@include secondary-grid-column(8);
			margin-left: secondary-grid-column(2);
		}
	}
	&__content {
		@include secondary-grid-column(18);
		margin-left: secondary-grid-column(2);
		@include breakpoint( medium ) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(1);
		}
		@include breakpoint( large ) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(2);
		}
	}

	&__links-list {
		@include resetList;
		padding-top: rem(30);
	}
	&__links-item {
		padding: 0 rem(20) rem(20);
	}
}
