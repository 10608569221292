.block-link-list {

	@include modulePaddingTop();

	&__wrapper {
		@include secondary-grid-row;
	}
	&__content {
		@include secondary-grid-column(20);
		margin-left: secondary-grid-column(2);
		@include breakpoint( medium ) {
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(4);
		}
		@include breakpoint( large ) {
			@include secondary-grid-column(12);
			margin-left: secondary-grid-column(6);
		}
	}

}
