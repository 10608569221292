.campaign-takeover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: white;
	z-index: 100;

	display: flex;
    flex-direction: column;
    align-items: center;
    
    background-size: cover;
    background-position: center;
    
    h2 {
    	font-size: 26px;
    	line-height: 30px;
    	max-width: 16rem;
    	margin-top: 4.75rem;
    	margin-bottom: 2rem;

    	text-align: center;    	

    	@include breakpoint(medium) {
			@include h1FontSize;
    		line-height: 80px;
    		max-width: 60rem;
    		margin-top: 7.5rem;
	    	margin-bottom: 3.75rem;
		}
    }

    .button--standard {

    	@include breakpoint(medium) {
	    	font-size: 2rem;
    	}
    }

    &__branding {
    	position: absolute;

    	top: 1.75rem;
    	left: 1.75rem;
    	
    	@include breakpoint(medium) {
	    	top: 2rem;
    		left: 2rem;
    	}

    	a {
	    	color: get-color(darkBlue);
    	}
    }

    &__close {
    	position: absolute;
    	top: 1.75rem;
    	right: 1.75rem;

    	@include breakpoint(medium) {
	    	top: 2rem;
    		right: 2rem;
    	}
    }
}