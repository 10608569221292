.full-screen-hero {

	overflow: hidden;
	position: relative;
	min-height: 85vh;

	&__media {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		// background-color: #000;

	}

	&__media-container {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// opacity: 0.75;

		img,
		video {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.power-save-mode-fallback-image {
			position: absolute;
		    top: 0;
		    left: 0;
		}
	}


	&__overlay {
		position: relative;
		z-index: 2;
		min-height: 85vh;
		display: flex;
		flex-direction: column;
	}


	&__content {
		flex-grow: 1;
		padding: rem(120) 0 rem(50);

		h1, h2, h3 {
			color: inherit;
		}

	}

	&__content-wrapper {
		@include secondary-grid-row();
	}

	&__content-container {

		@include largerFontSize;
		@include secondary-grid-column(24);
		color: $white;

		@include breakpoint( medium ) {
			@include secondary-grid-column(18);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint( large ) {
			// @include secondary-grid-column(10);
			// margin-left: secondary-grid-column(2);
		}

		.cta-buttons {
			@include standardFontSize();
			padding-top: rem(30);
		}

	}

	&__cta {
		padding-bottom: rem(80);
	}

	&__cta-wrapper {
		padding-top: rem(150);
		@include secondary-grid-row();
	}
	&__cta-content {
		color: $white;
		@include secondary-grid-column(24);
		@include breakpoint( medium ) {
			@include secondary-grid-column(20);
			margin-left: secondary-grid-column(2);
		}
	}

	&__cta-list {
		@include resetList;
		display: flex;
		flex-wrap: wrap;
	}
	&__cta-list-item {
		margin-right: rem(20);
		margin-bottom: rem(20);
	}

}
