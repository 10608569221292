.sub-header {
	@include smallerFontSize;
	z-index: 9;
	width: 100%;

	background-color: get-color(warmGray60);

	&__wrapper {
		@include row();
		width: 100%;
		padding-top: rem(14);
		padding-bottom: rem(14);

		@include breakpoint(medium) {
			padding-top: rem(16);
			padding-bottom: rem(16);
		}
		@include breakpoint(large) {
			padding-top: rem(18);
			padding-bottom: rem(18);
		}
		@include breakpoint(xlarge) {
			padding-top: rem(20);
			padding-bottom: rem(20);
		}

		will-change: padding;
		transition: padding 0.2s ease-out;
	}

	&__breadcrumb-container {
		@include flex-column(12);
	}

	&__breadcrumb-list {
		@include resetList;
		display: flex;
		flex-wrap: wrap;
	}
	&__breadcrumb-item {
		margin-right: rem(10);
		a {
			color: inherit;
		}
	}

	&__breadcrumb-item-inner {
		display: flex;
		align-items: center;
		svg {
			margin-left: rem(10);
		}
	}

	&__navigation-container {
		@include flex-column(8);
	}
	&__navigation-list {
		@include resetList;
		display: flex;
		justify-content: flex-end;
	}
	&__navigation-item {
		margin-left: rem(20);
	}
}

.company-header {
	@include smallerFontSize;
	z-index: 9;
	width: 100%;
	padding: rem(8) 0 rem(7);
	position: sticky;
	top: 0;

	color: $white;
	background-color: get-color(darkBlue);

	@include breakpoint(medium) {
		padding: rem(16) 0;
	}
	@include breakpoint(large) {
		padding: rem(22) 0;
	}

	a, button {
		color: inherit;
	}

	&__wrapper {
		@include row();
		align-items: center;
	}

	&__company {
		@include flex-column(8);
		text-transform: uppercase;
		@include breakpoint(large) {
			@include flex-column(4);
		}
	}

	&__mobile-ui {
		@include flex-column(4);
		text-align: right;
		@include breakpoint(large) {
			display: none;
		}
	}

	&__toggle-button {
		width: rem(36);
		height: rem(36);
	}

	&--is-open &__toggle-button {
		transform: rotate(180deg);
	}

	&__navigation {
		@include flex-column(12);
		@include breakpoint(large) {
			@include flex-column(8);
		}
	}

	&--is-closed &__navigation {
		@include breakpoint( medium down ) {
			display: none;
		}
	}

	&__navigation-list {
		@include resetList();
		@include breakpoint( medium down ) {
			padding-top: rem(15);
			padding-bottom: rem(10);
		}
		@include breakpoint(large) {
			display: flex;
			justify-content: flex-end;
		}
	}
	&__navigation-item {
		@include breakpoint( medium down ) {
			// font-size: rem(16);
			margin-bottom: rem(15);
		}
		@include breakpoint(large) {
			&:not(:last-child) {
				margin-right: rem(20);
			}
		}
	}
}
