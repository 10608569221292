.block-image-divider {

	height: calc( 50vh + 120px );

	&--standard {
		@include modulePaddingTop;
	}

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

}
