.content-container {

	@include modulePaddingTop;

	&--add-margin-bottom {
		@include moduleBottomMargin;
	}

	&__header-wrapper {
		@include row();
		align-items: center;
		margin-bottom: rem(40);
		@include breakpoint( large ) {
			margin-bottom: rem(60);
		}
	}
	&__header-title {
		@include flex-column(12);
		@include breakpoint( medium ) {
			@include flex-column(8);
		}
		h2 {
			margin: 0;
		}
	}
	&__header-cta {
		@include flex-column(12);
		@include breakpoint( small only ) {
			padding-top: rem(25);
		}
		@include breakpoint( medium ) {
			@include flex-column(4);
			text-align: right;
		}
	}
	&--insights-module {
		background-color: get-color(warmGray40);

		.content-container__header-title {
			h2 {
				color: #333333;
			}
		}

		.content-container__header-cta {
			display: none;
			@include breakpoint(medium) {
				display: block;
			}
		}
	}
}
