.downloads-list {

	&__item {

		@include secondary-grid-row();

	}

	&__item-inner {
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid get-color(permafrost);
		padding-top: rem(15);
		padding-bottom: rem(15);
	}

	&__item-type {
		@include secondary-grid-column(3);
		// @include breakpoint( medium ) {
		// 	@include secondary-grid-column(3);
		// }
		@include breakpoint( large ) {
			@include secondary-grid-column(2);
		}

		svg {
			max-width: 100%;
			height: auto;
		}

	}
	&__item-title {
		@include secondary-grid-column(15);
		margin-left: secondary-grid-column(1);
		// @include breakpoint( medium ) {
		// 	@include secondary-grid-column(3);
		// }
		@include breakpoint( large ) {
			@include secondary-grid-column(18);
			margin-left: 0;
		}
	}
	&__item-link {
		text-align: right;
		@include secondary-grid-column(4);
		margin-left: secondary-grid-column(1);
		@include breakpoint( large ) {
			@include secondary-grid-column(4);
			margin-left: 0;
		}

		.button {
			text-overflow: unset;
		
			&-label {
				@include breakpoint( small only ) {
					display: none;
				}
			}
		}
	}

}


/* Offerings page */
.offerings-page {
	.downloads-list {
		&.bottom-spacing {
			@include modulePaddingBottom();
		}
	}
}