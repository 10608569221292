// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group flex-grid
////

/// Calculates the width of a column based on a number of factors.
///
/// @param {Number|List} $columns
///   Width of the column. Accepts multiple values:
///   - A percentage value will make the column that exact size.
///   - A single digit will make the column span that number of columns wide, taking into account the column count of the parent row.
///   - A string of the format "x of y" will make a column that is *x* columns wide, assuming *y* total columns for the parent.
///
/// @returns {Number} A calculated percentage value.
@use "sass:math";

@function grid-column($columns) {
	$width: 0%;

	// Parsing percents, decimals, and column counts
	@if type-of($columns) == "number" {
		@if unit($columns) == "%" {
			$width: $columns;
		} @else if $columns < 1 {
			$width: percentage($columns);
		} @else {
			$width: percentage(math.div($columns, $grid-column-count));
		}
	}

	@return $width;
}


@function secondary-grid-column($columns) {
	$width: 0%;

	// Parsing percents, decimals, and column counts
	@if type-of($columns) == "number" {
		@if unit($columns) == "%" {
			$width: $columns;
		} @else if $columns < 1 {
			$width: percentage($columns);
		} @else {
			$width: percentage(math.div($columns, $secondary-grid-column-count));
		}
	}

	@return $width;
}

@function custom-grid-column( $columns, $columnCount ) {

	$width: 100%;

	@if $columns < 1 {
		$width: percentage($columns);
	} @else {
		$width: percentage(math.div($columns, $columnCount));
	}

	@return $columnCount;

}


/// Inverts the margins of a row to nest it inside of a column.
///
/// @param {Map|null} $gutter [null] - Gutter value to use when inverting the margins. Set to `null` to refer to the responsive gutter settings.
@mixin grid-row-nest($gutter: $grid-column-gutter) {
	@if type-of($gutter) == "number" {
		$gutter: (
			$-zf-zero-breakpoint: $gutter,
		);
	}

	@each $breakpoint, $value in $gutter {
		$margin: math.div(rem($value), 2) * -1;

		@include breakpoint($breakpoint) {
			margin-left: $margin;
			margin-right: $margin;
		}
	}
}

/// Creates a container for a flex grid row.
///
/// @param {Number} $width [$grid-row-width] - Maximum width of the row.
/// @param {Boolean} $base [true] - Set to `false` to prevent basic styles from being output. Useful if you're calling this mixin on the same element twice, as it prevents duplicate CSS output.
/// @param {Number} $gutter [$grid-column-gutter] - Gutter to use when inverting margins, in case the row is nested.
@mixin flex-row($width: $grid-row-width, $base: true, $gutter: $grid-column-gutter) {

	max-width: $width;
	margin-left: auto;
	margin-right: auto;

	@if $base {
		display: flex;
		flex-flow: row wrap;
	}

}

/// Calculates the `flex` property for a flex grid column. It accepts all of the same values as the basic `grid-column()` function, along with two extras:
///   - `null` (the default) will make the column expand to fill space.
///   - `shrink` will make the column contract, so it only takes up the horizontal space it needs.
///
/// @param {Mixed} $columns [null] - Width of the column.
@function flex-column($columns: null) {
	// scss-lint:disable ZeroUnit
	$flex: 1 1 0px;

	@if $columns == shrink {
		$flex: 0 0 auto;
	} @else if $columns != null {
		$flex: 0 0 grid-column($columns);
	}

	@return $flex;
}

/// Creates a column for a flex grid. By default, the column will stretch to the full width of its container, but this can be overridden with sizing classes, or by using the `unstack` class on the parent flex row.
///
/// @param {Mixed} $columns [null] - Width of the column. Refer to the `flex-column()` function to see possible values.
/// @param {Number} $gutter [$grid-column-gutter] - Space between columns, added as a left and right padding.
@mixin flex-column($columns: null, $gutter: $grid-column-gutter) {
	// Base properties
	flex: flex-column($columns);

	// Gutters
	@if type-of($gutter) == "map" {
		@each $breakpoint, $value in $gutter {
			$padding: math.div(rem($value), 2);

			@include breakpoint($breakpoint) {
				padding-left: $padding;
				padding-right: $padding;
			}
		}
	} @else if type-of($gutter) == "number" and strip-unit($gutter) > 0 {
		$padding: math.div(rem($gutter), 2);
		padding-left: $padding;
		padding-right: $padding;
	}

	// fixes recent Chrome version not limiting child width
	// https://stackoverflow.com/questions/34934586/white-space-nowrap-and-flexbox-did-not-work-in-chrome
	@if $columns == null {
		min-width: initial;
	}
	// max-width fixes IE 10/11 not respecting the flex-basis property
	@if $columns != null and $columns != shrink {
		max-width: grid-column($columns);
	}
}


@mixin secondary-grid-column($columns: null) {

	$width: secondary-grid-column($columns);
	flex: 0 0 $width;
	max-width: $width;

}


// Adjusts the flex column size without duplication padding for every breakpoint
//
/// @param {Mixed} $columns [null] - Width of the column. Refer to the `flex-column()` function to see possible values.
@mixin flex-column-size( $columns: null ) {

	@if type-of($columns) == "number" and strip-unit($columns) > 0 {
		flex: 0 0 grid-column($columns);
		max-width: grid-column($columns);
	}

}

/// Creates a block grid for a flex grid row.
///
/// @param {Number} $n - Number of columns to display on each row.
/// @param {String} $selector - Selector to use to target columns within the row.
@mixin flex-grid-layout($n, $selector: ".column") {
	flex-wrap: wrap;

	> #{$selector} {
		$pct: percentage(math.div(1, $n));

		flex: 0 0 $pct;
		max-width: $pct;
	}
}
