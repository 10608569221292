.site-header {

	position: relative;
	@include smallerFontSize;
	z-index: 12;
	width: 100%;

	a {
		color: inherit;
	}

	// will-change: background-color, color;
	// transition: background-color 0.25s linear, color 0.25s linear;

	&--visibility-hidden {
		// background-color: $white;
		// color: $black;
	}

	// Open menu
	&--navigation-is-visible {
		background-color: get-color(darkBlue);
		color: $white;

		.site-header__branding {
			color: get-color(white);
		}

		.toggle-mobile-menu {
			svg {
				line {
					stroke: get-color(white);
				}
			}
		}
	}

	&__wrapper {
		@include row();
		width: 100%;
		padding-top: rem(20);
		padding-bottom: rem(20);
		align-items: center;

		@include breakpoint(medium) {
			padding-top: rem(22);
			padding-bottom: rem(18);
		}
		// @include breakpoint(large) {
		// 	padding-top: rem(25);
		// 	padding-bottom: rem(25);
		// }
		// @include breakpoint(xlarge) {
		// 	padding-top: rem(28);
		// 	padding-bottom: rem(28);
		// }

		will-change: padding;
		transition: padding 0.2s ease-out;
	}

	&--visibility-hidden &__wrapper {
		padding-top: rem(10);
		padding-bottom: rem(10);
		@include breakpoint(medium) {
			padding-top: rem(15);
			padding-bottom: rem(15);
		}
		@include breakpoint(xlarge) {
			padding-top: rem(20);
			padding-bottom: rem(20);
		}
	}

	&__branding {
		@include flex-column(4);
		color: get-color(darkBlue);

		.site-container--theme-dark & {
			color: $white;
		}
		
		svg {
			width: 100%;
			height: auto;
			max-width: rem(140);
		}

		a {
			@include breakpoint(small only) {
				display: block;
				width: rem(140);
			}
		}
	}

	&__navigation-container {
		@include flex-column(8);

		ul {
			@include resetList;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		li {
			margin-left: rem(30);

			@include breakpoint(small only) {
				margin-bottom: 0;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}

		button {
			color: inherit;
			&:hover {
				text-decoration: underline;
			}
		}

		.login-button {
			&:hover {
				text-decoration: none;
			}

			.link-button__label {
				padding-top: 0;
			}
		}

	}

	&__sub-navigation {

		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		padding: rem(50) 0 rem(100);

		background-color: get-color(darkBlue);
		color: $white;

		h1, h2, h3, h4, h5, h6 {
			color: inherit;
		}

		h3 {
			margin-bottom: 4px;
		}

		p {
			margin-bottom: 10px;
		}

		&__wrapper {
			@include row();
		}
		&__top-level {
			@include flex-column(12);
			margin-bottom: rem(80);
			p {
				margin: 0 0 rem(20);
				&:last-child {
					margin: 0;
				}
			}
			.read-more {
				color: get-color(permafrost);

				.arrow {
					margin-right: 0.5rem;
				}
			}
		}
		&__links {
			display: grid;
    		grid-template-columns: repeat(3, minmax(0, 1fr));
    		align-items: self-start;
    		width: 100%;

    		.site-header__sub-navigation__secondary-level {
    			max-width: none;
    			margin-bottom: 0;
    			min-height: 5.5rem;

    			ul {
    				margin-bottom: 2.5rem;
    			}
    		}

    		@include breakpoint( xlarge ) {
	    		grid-template-columns: repeat(4, minmax(0, 1fr));
    		}
		}
		&__secondary-level {
			@include flex-column(12);
			margin-bottom: rem(15);

			@include breakpoint( medium ) {
				@include flex-column-size(6);
				margin-bottom: rem(20);
			}
			@include breakpoint( large ) {
				@include flex-column-size(4);
				margin-bottom: rem(30);
			}
			@include breakpoint( xlarge ) {
				@include flex-column-size(3);
				margin-bottom: rem(40);
			}

			h4 {
				color: get-color(permafrost);
				margin-bottom: 18px;

				a {
					text-decoration: none;
					&:hover {
						text-decoration: none;
						color: $white;
					}
				}
			}

			ul {
				@include resetList;
			}

			li {

				&:not(:last-child) {
					margin-bottom: rem(10);
				}

				a {
					text-decoration: none;
					&:hover {
						text-decoration: none;
						color: get-color(permafrost);
					}
				}
			}

		}
	}
}



.link-button {

	display: inline-block;
	padding: rem(6) rem(18);
	border: 1px solid currentColor;
	border-radius: 3px;



	display: flex;
	align-items: center;

	span {
		display: block;
	}

	&__label {
		padding-top: rem(4);
		padding-left: rem(10);
		line-height: 1;
	}
	&__icon {
		svg {
			display: block;
		}
	}

	&:hover {

		text-decoration: none;

		.site-header--navigation-is-hidden & {

			background-color: get-color(darkBlue);
			border-color: get-color(darkBlue);
			color: get-color(white);

		}

	}

	.site-header--navigation-is-visible & {

		color: get-color(permafrost);

		&:hover {
			background-color: get-color(permafrost);
			border-color: get-color(permafrost);
			color: get-color(darkBlue);
		}

	}

}

