.site-container--theme-home {
	.insights-grid {
		&__inner {
			@include breakpoint(medium) {
				display: grid;
				grid-template-columns: repeat(20,minmax(0,1fr));
				gap: rem(80) 0;
			}
		}
		&__item {
			@include breakpoint(medium) {
				&:first-child {
					grid-column: 1 / span 13;
					grid-row: 1 / span 2;

					.insight-card__text {
						margin-top: rem(40);
								
						.insight-title {
							font-size: 1.625rem;
							line-height: 1.35;
							margin-bottom: rem(20);

							@include breakpoint(medium) {
								font-size: 2rem;
							}
							@include breakpoint(xlarge) {
								font-size: 3rem;
								line-height: 52px;
							}
						}
					}
				}

				&:nth-child(2), &:nth-child(3) {
					grid-column: 15 / span 6;

					.insight-card__text {
						.insight-text {
							display: none;
						}
					}
				}
			}
		}
	}
}

.insights-grid {
	padding-bottom: rem(140);

	&__wrapper {
		@include row();
	}
	&__content {
		@include flex-column(12);
	}

	&__inner {
		@include breakpoint(medium) {
			display: grid;
			grid-template-columns: repeat(3,minmax(0,1fr));
			gap: 0 rem(56);
			padding: 0 0;
		}
		@include breakpoint(xlarge) {
			padding: 0 secondary-grid-column(2);
		}
	}

	&__item {
		margin-bottom: rem(60);

		@include breakpoint(medium) {
			grid-column: auto / span 1;
			margin-bottom: 0;
		}

		.insight-text {
			padding: 0 secondary-grid-column(2);

			@include breakpoint(medium) {
				padding: 0;
			}
		}
	}

	.insights-link-mobile {
		display: block;
		margin-left: auto;
		margin-right: 0.46875rem;
		@include breakpoint(medium) {
			display: none;
		}
	}
}
