.pagination-container {

	@include modulePaddingTop;
	@include moduleBottomMargin;

	&__wrapper {
		@include row();
	}
	&__content {
		@include flex-column(12);
	}

}

.pagination-list {

	@include resetList;
	display: flex;
	justify-content: center;

	&__item {
		padding: 0 rem(12);
		@include breakpoint( medium ) {
			padding: 0 rem(16);
		}
		@include breakpoint( large ) {
			padding: 0 rem(20);
		}

		&.selected {
			font-weight: $global-weight-bold;
		}

	}

	&__link {
		cursor: pointer;
		color: get-color(darkBlue);
	}

	&__item.disabled &__link {

		pointer-events: none;
		color: get-color(mediumBlue);
		opacity: 0.6;

	}

}

.insights-pagination-list {
	@include resetList;
	display: flex;
    flex-direction: column;
	align-items: center;
	color: get-color(darkBlue);
	
	&__item {
		font-size: rem(32);
		order: -1;
		

		.insights-pagination-list__link {
			cursor: default;
		}
	}

	&__link {
		cursor: pointer;

		&.disabled {
			pointer-events: none;
			opacity: 0;
		}
	}
}
