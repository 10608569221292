.collapsible-list {

	padding-top: rem(80);

	&__header {
		@include secondary-grid-row;
		margin-bottom: rem(50);
	}
	&__header-content {
		@include secondary-grid-column(12);
		margin-left: secondary-grid-column(6);
	}

	&__wrapper {
		@include secondary-grid-row;
	}
	&__content {
		@include secondary-grid-column(24);
		@include breakpoint( medium ) {
			@include secondary-grid-column(12);
			margin-left: secondary-grid-column(6);
		}
	}

	&__list {
		@include resetList;
		border-top: 1px solid currentColor;
	}

	&__list-item {
		border-bottom: 1px solid currentColor;

	}

	&__list-item-inner {
		display: block;
	}

	&__list-item-question {
		@include h3FontSize;
		text-align: left;
		cursor: pointer;
		display: flex;
		align-items: flex-start;
		width: 100%;
		padding-top: rem(25);
		padding-bottom: rem(25);
	}

	&__list-item-question-text {
		pointer-events: none;
		flex-grow: 1;
	}

	&__list-item-question-icon {
		width: rem(50);
		flex: 0 0 rem(50);
		padding: rem(9) rem(4) 0 rem(30);
		svg {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__list-item--is-open &__list-item-question-icon {
		transform: rotate(180deg);
		padding: 0 rem(30) rem(9) rem(4);
	}

	&__list-item-answer {
		padding-bottom: rem(25);

		h2, h3, h4 {
			@include largerFontSize;
		}

	}

}
