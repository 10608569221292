.morningstar {
	@include modulePaddingTop;

	&__wrapper {
		@include secondary-grid-row;
	}

	&__media {
		position: relative;
		@include secondary-grid-column(24);
		@include breakpoint(medium) {
			@include secondary-grid-column(12);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(2);
		}

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	&__content {
		background-color: get-color(warmGray60);
		padding-top: rem(48);
		padding-bottom: rem(48);
		padding-left: secondary-grid-column(1);
		padding-right: secondary-grid-column(1);

		@include secondary-grid-column(24);
		@include breakpoint(medium) {
			@include secondary-grid-column(12);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(10);
		}
	}

	&__content-block {

		h2, h3 {
			margin: 0 0 rem(40);
		}

		p {
			font-size: rem(24);

			&:last-child {
				margin: 0;
			}
		}

	}
}
