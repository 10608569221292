@use "sass:math";

@mixin outer-padding-left() {
	padding-left: map-get($outer-padding, "small");
	@include breakpoint(medium) {
		padding-left: map-get($outer-padding, "medium");
	}
	@include breakpoint(large) {
		padding-left: map-get($outer-padding, "large");
	}
	@include breakpoint(xlarge) {
		padding-left: map-get($outer-padding, "xlarge");
	}
}

@mixin outer-padding-right() {
	padding-right: map-get($outer-padding, "small");
	@include breakpoint(medium) {
		padding-right: map-get($outer-padding, "medium");
	}
	@include breakpoint(large) {
		padding-right: map-get($outer-padding, "large");
	}
	@include breakpoint(xlarge) {
		padding-right: map-get($outer-padding, "xlarge");
	}
}


@mixin outer-padding() {
	@include outer-padding-left;
	@include outer-padding-right;
}

@mixin column-padding() {
	padding-left: math.div(map-get($grid-column-gutter, "small"), 2);
	padding-right: math.div(map-get($grid-column-gutter, "small"), 2);
	@include breakpoint(medium) {
		padding-left: math.div(map-get($grid-column-gutter, "medium"), 2);
		padding-right: math.div(map-get($grid-column-gutter, "medium"), 2);
	}
	@include breakpoint(large) {
		padding-left: math.div(map-get($grid-column-gutter, "large"), 2);
		padding-right: math.div(map-get($grid-column-gutter, "large"), 2);
	}
	@include breakpoint(xlarge) {
		padding-left: math.div(map-get($grid-column-gutter, "xlarge"), 2);
		padding-right: math.div(map-get($grid-column-gutter, "xlarge"), 2);
	}
}

@mixin full-padding() {
	padding-left: map-get($outer-padding, "small") + math.div(map-get($grid-column-gutter, "small"), 2);
	padding-right: map-get($outer-padding, "small") + math.div(map-get($grid-column-gutter, "small"), 2);
	@include breakpoint(medium) {
		padding-left: map-get($outer-padding, "medium") + math.div(map-get($grid-column-gutter, "medium"), 2);
		padding-right: map-get($outer-padding, "medium") + math.div(map-get($grid-column-gutter, "medium"), 2);
	}
	@include breakpoint(large) {
		padding-left: map-get($outer-padding, "large") + math.div(map-get($grid-column-gutter, "large"), 2);
		padding-right: map-get($outer-padding, "large") + math.div(map-get($grid-column-gutter, "large"), 2);
	}
	@include breakpoint(xlarge) {
		padding-left: map-get($outer-padding, "xlarge") + math.div(map-get($grid-column-gutter, "xlarge"), 2);
		padding-right: map-get($outer-padding, "xlarge") + math.div(map-get($grid-column-gutter, "xlarge"), 2);
	}
}

// Flex rows

@mixin row() {
	@include flex-row();
	@include outer-padding();
}

@mixin secondary-grid-row() {
	@include flex-row();
	@include full-padding();
}


@mixin inner-row() {
	display: flex;
	@include inner-row-helper("small");
	@include breakpoint(medium) {
		@include inner-row-helper("medium");
	}
	@include breakpoint(large) {
		@include inner-row-helper("large");
	}
	@include breakpoint(xlarge) {
		@include inner-row-helper("xlarge");
	}
}


// Spacing

@mixin blockBottomMargin(
	$small: rem(30),
	$medium: rem(35),
	$large: rem(40),
	$xlarge: rem(50)
) {
	margin-bottom: $small;
	@include breakpoint(medium) {
		margin-bottom: $medium;
	}
	@include breakpoint(large) {
		margin-bottom: $large;
	}
	@include breakpoint(xlarge) {
		margin-bottom: $xlarge;
	}
}

@mixin moduleBottomMargin(
	$small: rem(80),
	$medium: rem(100),
	$large: rem(120),
	$xlarge: rem(150)
) {
	margin-bottom: $small;
	@include breakpoint( medium ) {
		margin-bottom: $medium;
	}
	@include breakpoint( large ) {
		margin-bottom: $large;
	}
	@include breakpoint( xlarge ) {
		margin-bottom: $xlarge;
	}
}

@mixin modulePaddingTop(
	$small: rem(50),
	$medium: rem(80),
	$large: rem(80), // 100
	$xlarge: rem(80) // 120
) {
	padding-top: $small;
	@include breakpoint( medium ) {
		padding-top: $medium;
	}
	@include breakpoint( large ) {
		padding-top: $large;
	}
	@include breakpoint( xlarge ) {
		padding-top: $xlarge;
	}
}

@mixin modulePaddingBottom(
	$small: rem(50),
	$medium: rem(80),
	$large: rem(100),
	$xlarge: rem(120)
) {
	padding-bottom: $small;
	@include breakpoint( medium ) {
		padding-bottom: $medium;
	}
	@include breakpoint( large ) {
		padding-bottom: $large;
	}
	@include breakpoint( xlarge ) {
		padding-bottom: $xlarge;
	}
}

@mixin pageTopPadding() {
	padding-top: rem(120);
	@include breakpoint( medium ) {
		padding-top: rem(140);
	}
	@include breakpoint( large ) {
		padding-top: rem(160);
	}
	@include breakpoint( xlarge ) {
		padding-top: rem(180);
	}
}


// Body font sizes
@mixin standardFontSize() {
	font-size: rem(15);
	@include breakpoint(medium) {
		font-size: rem(15);
	}
	@include breakpoint(large) {
		font-size: rem(16);
	}
	// @include breakpoint(xlarge) {
	// 	font-size: rem(18);
	// }
}


@mixin smallerFontSize() {
	font-size: rem(12);
	@include breakpoint(medium) {
		font-size: rem(12);
	}
	@include breakpoint(large) {
		font-size: rem(14);
	}
}


@mixin largerFontSize() {
	font-size: rem(16);
	@include breakpoint(medium) {
		font-size: rem(16);
	}
	@include breakpoint(large) {
		font-size: rem(18);
	}
	// @include breakpoint(xlarge) {
	// 	font-size: rem(24);
	// }
}


// Header font sizes
@mixin h1FontSize() {
	@each $size, $headers in $header-sizes {
		@include breakpoint($size) {
			font-size: rem(map-get($headers, "h1"));
		}
	}
}

@mixin h2FontSize() {
	@each $size, $headers in $header-sizes {
		@include breakpoint($size) {
			font-size: rem(map-get($headers, "h2"));
		}
	}
}

@mixin h3FontSize() {
	@each $size, $headers in $header-sizes {
		@include breakpoint($size) {
			font-size: rem(map-get($headers, "h3"));
		}
	}
}


// Utilities

@mixin resetList() {
	list-style-type: none;
	margin: 0;
	padding: 0;
}


@mixin inheritHeaderColor() {
	h1, h2, h3, h4, h5, h6 {
		color: inherit;
	}
}
