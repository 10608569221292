@import "includes/util";
@import "includes/mixins";

@import "includes/fonts";
@import "includes/settings";
@import "includes/normalize";
@import "includes/breakpoints";
@import "includes/flexgrid";

@import "includes/forms";
@import "includes/globals";
@import "includes/typography";

@import "globals/misc";
@import "globals/siteHeader";
@import "globals/siteHeaderMobile";
@import "globals/subHeader";
@import "globals/siteFooter";
@import "globals/gridOverlay";
@import "globals/popupOverlay";

@import "modules/fullScreenHero";
@import "modules/lockUpHero";
@import "modules/blockSingleMedia";
@import "modules/collapsibleList";
@import "modules/loginsList";


@import "blocks/articleHeader";
@import "blocks/pageHeaderBasic";
@import "blocks/body";
@import "blocks/contentContainer";
@import "blocks/dealCard";
@import "blocks/eventCard";
@import "blocks/featuredDeals";
@import "blocks/flexTable";
@import "blocks/fullServiceNavigation";
@import "blocks/insightCard";
@import "blocks/insightsGrid";
@import "blocks/linkList";
@import "blocks/lockUp";
@import "blocks/lockUpSimple";
@import "blocks/newsArticle";
@import "blocks/newsCard";
@import "blocks/pageBuilder";
@import "blocks/quote";
@import "blocks/sectionDivider";
@import "blocks/sectionHeader";
@import "blocks/sectorList";
@import "blocks/serviceOverview";
@import "blocks/slideshow";
@import "blocks/tagPill";
@import "blocks/twoColumnText";
@import "blocks/singleColumnText";
@import "blocks/threeColumnList";
@import "blocks/toggleButton";

@import "blocks/managementList";
@import "blocks/teamList";

@import "modules/lockUpFullScreen";
@import "modules/blockLinkList";

@import "modules/pagination";
@import "modules/dropdown";
@import "modules/calendar";
@import "modules/campaignTakeover";
@import "modules/insightsList";

@import "blocks/blockImageDivider";
@import "blocks/customContentList";
@import "blocks/downloadsList";
@import "blocks/aamPerformanceChart";
@import "blocks/holdingsList";
@import "blocks/podcastsList";
@import "blocks/featuredOffering";
@import "blocks/morningstar";
@import "blocks/keyFigures";
@import "blocks/researchReports";
@import "blocks/newsletter";
@import "blocks/videoGrid";

.fund-performance-app {

	@include modulePaddingTop;

	&__wrapper {
		@include row();
	}

	&__container {
		@include flex-column(12);
	}

}

