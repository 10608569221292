.three-column-list {
	@include resetList;
	@include row();
	&__item {
		@include flex-column(12);
		margin-bottom: rem(50);
		@include breakpoint(medium) {
			@include flex-column(6);
			margin-bottom: rem(60);
		}
		@include breakpoint(large) {
			@include flex-column(4);
			margin-bottom: rem(80);
		}
	}
}
