.team-list {

	@include modulePaddingTop;

	&__wrapper {
		@include row();
	}

	&__heading {
		@include flex-column(12);
		margin-bottom: rem(80);
	}

	&__item {
		@include flex-column(6);
		margin-bottom: rem(40);
		@include breakpoint( medium ) {
			@include flex-column(4);
		}
		@include breakpoint( large ) {
			@include flex-column(3);
		}


		&--no-image, &--with-image {
			.team-list__item-content {
		    	display: flex;
			    flex-direction: column;
				height: 100%;
			}
		}

		&--with-image {
			display: flex;
			flex-direction: column;
		}
	}

	&__item-inner {
		@include secondary-grid-row;
	}

	&__item-media {
		position: relative;
		margin-bottom: rem(20);
	}

	// &__item-media-container {

	// 	position: relative;
	// 	padding-top: 125%;

	// 	img {
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		object-fit: cover;
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// }

	&__item-media-placeholder {
		width: 100%;
		padding-top: 100%;
		background-color: get-color(warmGray60);
	}

	&__item-content {

		h4 {
			margin: 0;
		}

	}

	&__contact-list {
		@include resetList;
		margin-top: auto;
	}
	&__contact-list-item {
		&--email {
    		display: block;
			white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
		}
		&:not(:last-child) {
			margin-bottom: rem(10);
		}
	}


}
