// @TODO Figure out grid layout
.insights-list {
	@include secondary-grid-row;

	&__grid {
		@include resetList;
        
		@include breakpoint(medium) {
			display: grid;
			padding: 0 0;
		}
		@include breakpoint(xlarge) {
			padding: 0 secondary-grid-column(2);
		}
		&.top-grid {
			grid-template-columns: repeat(40,minmax(0,1fr));
			gap: rem(80) 0;
			// margin-bottom: rem(80);

			&:not(.paginated) {
				.insights-list__grid-item:first-child {
					grid-column: 1 / span 26;
					grid-row: 1 / span 2;

					img {
						aspect-ratio: 375/222;
						@include breakpoint(medium) {
							aspect-ratio: 720/400;
						}
					}

					.insight-card__text {
						margin-top: rem(40);
								
						.insight-title {
							font-size: 1.625rem;
							line-height: 1.35;
							margin-bottom: rem(20);

							@include breakpoint(medium) {
								font-size: 2rem;
							}
							@include breakpoint(xlarge) {
								font-size: 3rem;
								line-height: 52px;
							}
						}
					}
				}

				.insights-list__grid-item {
					&:nth-child(2), &:nth-child(3) {
						grid-column: 29 / span 12;

						img {
							aspect-ratio: 332/222;
						}

						.insight-card__text {
							.insight-text {
								@include breakpoint(medium) {
									display: none;
								}
							}
						}
					}
				}
			}
		}

		// &.three-column-grid {
		// 	grid-template-columns: repeat(3,minmax(0,1fr));
		// 	gap: rem(80) rem(56);
		// }

		// &.two-column-grid {
		// 	grid-template-columns: repeat(2,minmax(0,1fr));
		// 	gap: rem(80) rem(50);
		// }

		&-item {
			grid-column: auto / span 12;
			margin-bottom: rem(60);
			
			@include breakpoint(medium) {
				margin-bottom: 0;
			}

			.insight-text {
				padding: 0 secondary-grid-column(2);

				@include breakpoint(medium) {
					padding: 0;
				}
			}
			// &:nth-child(1n) {
			// 	grid-column: 1/ span 6;
			// }
			// &:nth-child(2n) {
			// 	grid-column: 8/ span 6;
			// }
			// &:nth-child(3n + 1) {
			// 	grid-column: 1 / span 6;
			// }
			// &:nth-child(3n + 2) {
			// 	grid-column: 8 / span 6;
			// }
			// &:nth-child(3n + 3) {
			// 	grid-column: 15 / span 6;
			// }
            &.items-3 {
                // grid-column: auto / span 12;
                // padding-right: rem(28);
                border-right: 1px solid #FFF;
                &.span-1 {
                    grid-column: 1 / span 12;
                }
                &.span-2 {
                    grid-column: 15 / span 12;
                }
                &.span-3 {
                    grid-column: 29 / span 12;
                }

				img {
					aspect-ratio: 325/222;
					@include breakpoint(medium) {
						aspect-ratio: 332/222;
					}
				}
            }
            &.items-2 {
                // grid-column: auto / span 19;
				// padding-right: rem(28);
                border-right: 1px solid #FFF;
                &.span-1 {
                    grid-column: 1 / span 19;
                }
                &.span-2 {
                    grid-column: 22 / span 19;
                }
				img {
					aspect-ratio: 325/222;
					@include breakpoint(medium) {
						aspect-ratio: 529/352;
					}
				}
            }
            
			// &:nth-child(7) {
			// 	grid-column: 1 / span 10;
			// 	padding-right: rem(28);
			// }
			// &:nth-child(8) {
			// 	grid-column: 11 / span 10;
			// 	padding-left: rem(28);
			// }
			&.featured {
				grid-column: 1 / span 40;
				img {
					aspect-ratio: 375/222;
					@include breakpoint(medium) {
						aspect-ratio: 1108/616;
					}
				}
            }
		}
	}
}