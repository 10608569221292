.custom-content-list {

	@include modulePaddingTop;

	&__wrapper {
		@include row();
	}

	&__header {
		@include flex-column(12);
		margin-bottom: rem(60);
		h2 {
			margin: 0;
		}
	}
	&__item {
		@include flex-column(12);
		display: flex;
    	flex-direction: column;
		margin-bottom: rem(50);

		&--size-two-column {
			@include breakpoint( medium ) {
				@include flex-column-size(6);
			}
		}

		&--size-three-column {
			@include breakpoint( medium ) {
				@include flex-column-size(6);
			}
			@include breakpoint( large ) {
				@include flex-column-size(4);
			}
		}

		&--size-four-column {
			@include flex-column-size(6);
			@include breakpoint( medium ) {
				@include flex-column-size(4);
			}
			@include breakpoint( large ) {
				@include flex-column-size(3);
			}
		}

		.button-container {
			margin-top: auto;
		}

	}

}
