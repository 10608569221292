
.lock-up-hero {

	position: relative;

	// height: 80vh;
	// min-height: rem(600);

	@include breakpoint( large ) {
		height: 80vh;
		min-height: rem(600);
	}

	&__media {

		@include breakpoint( large ) {

			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			display: flex;
			pointer-events: none;

		}

	}

	&--align-image-left &__media {
		justify-content: flex-start;
	}

	&--align-image-right &__media {
		justify-content: flex-end;
	}

	&__media-wrapper {

		position: relative;
		height: 100%;

		@include breakpoint( small only ) {
			padding-top: 75%;
		}
		@include breakpoint( medium only ) {
			padding-top: 56.25%;
		}

		@include breakpoint( large ) {
			@include secondary-grid-column(12);
		}
		@include breakpoint( xlarge ) {
			@include secondary-grid-column(10);
		}


	}

	&__media-container {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		video,
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

	}

	&__content {

		height: 100%;
		color: $white;
		background: $gradient-blue;

		@include inheritHeaderColor;

	}

	&--color-gray &__content {
		color: get-color(darkblue);
		background: $gradient-gray;
	}

	&__content-wrapper {

		height: 100%;
		@include secondary-grid-row;
		align-items: center;

	}

	&--color-gray &__content-wrapper {
		color: get-color(darkblue);
		background: $gradient-gray;
	}

	&__content-container {

		padding-top: rem(60);
		padding-bottom: rem(80);
		@include breakpoint( medium ) {
			padding-top: rem(80);
			padding-bottom: rem(100);
			@include secondary-grid-column(20);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint( large ) {
			padding-top: rem(120);
			padding-bottom: rem(150);
			@include secondary-grid-column(11);
		}
		@include breakpoint( xlarge ) {
			@include secondary-grid-column(10);
		}

	}

	&--align-image-left &__content-container {

		@include breakpoint( large ) {
			margin-left: secondary-grid-column(13);
		}
		@include breakpoint( xlarge ) {
			margin-left: secondary-grid-column(14);
		}

	}

	&--align-image-right &__content-container {

		@include breakpoint( large ) {
			margin-left: 0;
		}
		@include breakpoint( xlarge ) {
			margin-left: secondary-grid-column(2);
		}

	}

}

