@font-face {
	font-family: "Formular";
	src: url("/fonts/formular/Brownfox-FormularLight.otf") format("opentype");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "Formular";
	src: url("/fonts/formular/Brownfox-Formular.otf") format("opentype");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Formular";
	src: url("/fonts/formular/Brownfox-FormularItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "Formular";
	src: url("/fonts/formular/Brownfox-FormularMedium.otf") format("opentype");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "Formular";
	src: url("/fonts/formular/Brownfox-FormularBold.otf") format("opentype");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "Formular";
	src: url("/fonts/formular/Brownfox-FormularBoldItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 700;
}

