@use "sass:list";

$grid-overlay-color: rgba(255, 0, 255, 0.05);
$secondary-grid-overlay-color: rgba(0, 0, 0, 0.15);

.grid-overlay {
    position: fixed;
    top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    z-index: 9000;
    width: 100%;
    height: 100%;
    pointer-events: none;
    // display: none;

    &--active {
        display: block;
    }

    &__wrapper {
        @include row();
        height: 100%;
    }
    &__column {
        @include flex-column(1);
        height: 100%;
    }
    &__column-inner {
        width: 100%;
        height: 100%;
        background-color: $grid-overlay-color;
    }
    &__secondary-grid-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__secondary-grid-wrapper {
        @include secondary-grid-row();
        height: 100%;
    }
    &__secondary-grid-column {
        @include secondary-grid-column(1);
        height: 100%;
    }
    &__secondary-grid-column-inner {
        width: 100%;
        height: 100%;
        // border-left: 1px solid #000;
        box-shadow: -1px 0 0 $secondary-grid-overlay-color;
    }
    &__secondary-grid-column:last-child &__secondary-grid-column-inner {
        box-shadow: -1px 0 0 $secondary-grid-overlay-color, 1px 0 0 $secondary-grid-overlay-color;
    }
    
}
