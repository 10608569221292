:root {
	--swiper-pagination-top: 100%;
	--swiper-pagination-bullet-size: #{rem(12)};
	--swiper-pagination-bullet-horizontal-gap: #{rem(10)};
	--swiper-pagination-color: #{get-color(permafrost)};
	--swiper-pagination-bullet-inactive-color: #{get-color(permafrost)};
	--swiper-pagination-bullet-inactive-opacity: 0.4;

	@include breakpoint( large ) {
		--swiper-pagination-top: calc( 100% + 40px );
	}

}

.featured-deals {
	padding: rem(40) 0 rem(31);
	@include breakpoint(medium) {
		padding: rem(80) 0;
	}
	overflow: hidden;

	&--blue {
		margin-top: rem(120);
		background-color: get-color(darkBlue);
		color: $white;
	}

	&--white {
		background-color: $white;
		color: get-color(darkBlue);

		.deal-card__date {
			color: get-color(darkBlue);
			opacity: 0.6;
		}

		.deal-card__price {
			color: get-color(darkBlue);
		}
	}

	@include breakpoint( large ) {
		padding: rem(80) 0 rem(100);
	}

	h2, h3, h4 {
		color: inherit;
	}

	&__header {
		@include row();
		margin-bottom: rem(50);
	}
	&__header-content {
		@include breakpoint(medium) {
			@include flex-column(8);
		}
		h2 {
			margin: 0;
		}
	}

	&__header-link {
		&.mobile {
			display: block;
			margin-top: rem(80);
			float: inline-end;
			padding-right: rem(25);
		}
		&.desktop {
			display: none;
		}
	
		// Desktop styles
		@include breakpoint(medium) {
			&.mobile {
				display: none;
			}
	
			&.desktop {
				display: block;
				@include flex-column(4);
				text-align: right;
			}
		}
	}

	.swiper {
		overflow: visible;
	}

}
