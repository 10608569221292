.video-grid {
	@include modulePaddingTop;

	&__wrapper {
		@include secondary-grid-row;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		flex: 0 0 100%;
		max-width: 100%;
		gap: secondary-grid-column(1);

		@include breakpoint(large) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}