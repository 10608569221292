.newsletter {
	@include modulePaddingTop;
	padding-bottom: 7.5rem;

	background: get-color(darkBlue);
	color: $white;

	h2 {
		color: $white;
	}

	&__wrapper {
		@include flex-row();
		@include full-padding();
		justify-content: center;
	}
	&__container {
		@include secondary-grid-column(22);
		text-align: center;

		@include breakpoint( medium ) {
			@include secondary-grid-column(18);
		}
		@include breakpoint( large ) {
			@include secondary-grid-column(12);
		}
	}

	&--form {
		text-align: left;

		input {
			width: 100% !important;
    		border-radius: 8px !important;
    		margin-bottom: 1rem;
    	}

    	button {
    		background: $white;
    		color: get-color(darkBlue);
    	}
    }
}