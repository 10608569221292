
input,
label,
textarea {

    display: block;
    width: 100%;

}


form {

    position: relative;

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"] {
		@include smallerFontSize;
        height: 50px;
        border-radius: 25px;
        padding: 0 25px;
        box-shadow: none;
        outline: none;
        border: 1px solid $body-font-color;
    }

    textarea {
		@include smallerFontSize;
        width: 100%;
        max-width: 100%;
        max-height: 300px;
        border-radius: 25px;
        padding: 25px;
        box-shadow: none;
        outline: none;
        border: 1px solid $body-font-color;
    }

    label {
		@include smallerFontSize;
        padding: 0 0 rem(5) rem(25);
        // font-weight: $global-weight-bold;
    }

	button {

        cursor: pointer;
        font-size: rem-calc(14);
		height: 50px;
		padding: 0 25px;
        border: 1px solid $body-font-color;
        background-color: $body-font-color;
        color: $white;
		border-radius: 25px;

	}

}



.checkbox-container {
	position: relative;
	overflow: hidden;
	margin-bottom: rem-calc(25);

	input[type="checkbox"] {
		position: absolute;
		left: -99px;

		&:checked + label {
			.checkbox-placeholder {
				background-color: $body-font-color;
			}
		}

	}

	label {
		display: flex;
		align-items: flex-start;
		cursor: pointer;
	}
	.checkbox-placeholder {
		display: block;
        flex: 0 0 30px;
		width: 30px;
        height: 30px;
        border-radius: 15px;
		border: 2px solid $body-font-color;
		background-color: transparent;
		will-change: background-color;
		transition: background-color 0.15s;
    }
    .checkbox-label {
        flex: 0 0 1;
        font-size: rem-calc(14);
        padding-left: rem-calc(20);
    }

}

