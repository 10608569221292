.podcasts-list {
	&__wrapper {
		@include secondary-grid-row;
	}

	&__row {
		padding-top: 2.5rem;

		@include secondary-grid-row;
	}

	&__episode {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid get-color(darkBlue);

		@include breakpoint( large ) {
			flex-direction: row;
		}

		&:last-child {
			.podcasts-list__content-media {
				border-bottom: none;
			}
		}

	}

	&__header {
		display: flex;
	}

	&__thumbnail {
		padding-bottom: rem(40);

		@include breakpoint( large ) {
	    	padding-right: rem(50);
	    }

    	@include breakpoint( large ) {
			width: 50%;
    	}
	}

	&__publish-date {
		opacity: 0.6;
	}
	&__link-list {
		@include resetList;
	}
	&__content {
		padding-bottom: 1.5rem;

    	@include breakpoint( large ) {
			width: 50%;
    	}
	}

	&__content-media {

		// border-bottom: 1px solid get-color(darkBlue);
		// padding-bottom: 2.5rem;

		iframe {
			height: 352px;
		}
	}



	/* Styling for podcasts from CMS */

	&__row--compact {
		flex-direction: column;
		border-bottom: none;
		padding-top: 2.5rem;

		@include secondary-grid-row;

		.podcasts-list__content-media {
			width: 100%;
			border-bottom: 1px solid get-color(darkBlue);
			padding-bottom: 2.5rem;

			@include breakpoint( large ) {
				width: 66.66%;
				margin-top: 2.5rem;
			}

			iframe {
				width: 100%;
			}
		}

		.podcasts-list__content-header {
			@include breakpoint( large ) {
				display: flex;
				width: 66.66%;
				gap: 5rem;
			}

			& > * {
				@include breakpoint( large ) {
					width: 50%;
				}
			}
		}
	}
}
