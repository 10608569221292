.react-select-country-list {
	&-container {
		max-width: rem(390);
		border: 1.3px solid get-color(darkBlue);
		border-radius: rem(8);
		padding: rem(5) rem(22);
	}

	&__control {

	}

	&__placeholder {
		font-size: rem(14);
	}


	&__menu {
		left: 0;
		font-size: rem(14);
		box-shadow: 10px 10px 20px rgba(8, 21, 73, 0.1);
	}

	&__menu-list {
		background: $white;
		margin-top: rem(8);
	}

	&__group {
		&:first-child {
			margin-bottom: rem(43);
		}
	}

	&__option {
		padding: rem(11) rem(22);

		&:hover {
			background: #CED0DB;
		}
	}
}