.logins-list {

	@include modulePaddingTop;
	@include moduleBottomMargin;

	&__header {
		@include secondary-grid-row;
		margin-bottom: rem(50);
	}
	&__header-content {
		@include secondary-grid-column(18);
		margin-left: secondary-grid-column(3);
	}

	&__wrapper {
		@include secondary-grid-row;
	}
	&__content {
		@include secondary-grid-column(24);
		@include breakpoint( medium ) {
			@include secondary-grid-column(18);
			margin-left: secondary-grid-column(3);
		}
		@include breakpoint( large ) {
			@include secondary-grid-column(14);
			margin-left: secondary-grid-column(5);
		}
	}


	&__list {
		@include resetList;
		border-top: 1px solid currentColor;
	}

	&__group {
		border-bottom: 1px solid currentColor;
	}

	&__group-inner {
		display: block;
	}

	&__group-heading {
		@include h3FontSize;
		text-align: left;
		cursor: pointer;
		display: flex;
		align-items: flex-start;
		width: 100%;
		padding-top: rem(25);
		padding-bottom: rem(25);
		color: currentColor;
	}

	&__group-heading-text {
		pointer-events: none;
		flex-grow: 1;
	}

	&__group-question-icon {
		width: rem(50);
		flex: 0 0 rem(50);
		padding: rem(9) rem(4) 0 rem(30);
		svg {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__group--is-open &__group-question-icon {
		transform: rotate(180deg);
		padding: 0 rem(30) rem(9) rem(4);
	}

	&__group-content {
		display: none;
	}

	&__group--is-open &__group-content {
		display: block;
		padding-bottom: rem(40);
	}

	&__group-content-item {
		width: 100%;
		padding: rem(10) 0;
		display: flex;
	}

	&__group-content-text-container {
		flex-grow: 1;
	}

	&__group-content-heading {
		@include largerFontSize;
		display: inline-block;
		padding-top: rem(7);
	}

	&__group-content-link-container {
		flex: 0 0 35%;
		text-align: right;
		@include breakpoint( large ) {
			flex: 0 0 25%;
		}
	}

}
