.insight-card {
	position: relative;
	// height: 100%;
	// padding: rem(25);
	text-align: center;
	// background: $gradient-blue;
	// background-color: get-color(black);

	.insight-link {
		position: relative;
		color: get-color(darkBlue);
	}

	// @include breakpoint( medium ) {
	// 	padding: rem(30);
	// }
	// @include breakpoint( large ) {
	// 	padding: rem(40);
	// }

	&__background {
		// position: absolute;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		// z-index: 1;
		opacity: 0.85;

		will-change: opacity;
		transition: opacity 0.5s ease-out 0.3s;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&:hover &__background {
		opacity: 0.65;
		transition: opacity 0.25s ease-out 0s;
	}

	// &__inner {
	// 	position: relative;
	// 	z-index: 2;
	// 	height: 100%;
	// 	display: flex;
	// 	flex-direction: column;
	// 	justify-content: space-between;

	// 	h2,
	// 	h3 {
	// 		margin: 0;
	// 		color: inherit;
	// 	}

	// 	a {
	// 		color: inherit;
	// 		&:hover {
	// 			text-decoration: underline;
	// 		}
	// 	}

	// }

	// &__top {
	// 	margin-bottom: rem(40);
	// 	@include breakpoint( large ) {
	// 		margin-bottom: rem(60);
	// 	}
	// }

	&__tag-list {
		@include resetList;
		position: absolute;
		top: rem(20);
		right: rem(20);
		margin-bottom: rem(10);
		display: flex;
		color: $white;
		justify-content: flex-end;
	}
	&__tag {
		&:not(:first-child) {
			padding-left: rem(20);
		}

		.tag-pill {
			padding: rem(12) rem(15);
			border-radius: rem(50);
		}
	}

	&__text {
		margin-top: rem(20);

		.insight-title {
			font-size: rem(24);
			font-weight: 500;
			margin-bottom: rem(10);
			
			@include breakpoint(medium) {
				margin-bottom: rem(20);
			}
		}
	}

	.image-placeholder {
		width: 100%;
		height: rem(222);
		background-color: get-color(warmGray60);
		// max-height: rem(300);
	}

}
