.aam-performance-chart {

	background-color: get-color(darkBlue);

	&__wrapper {
		@include row();
	}

	&__container {
		@include flex-column(12);
	}

	&__content {

		width: 100%;
		padding: rem(100) rem(50);
		text-align: center;

		h2 {
			margin: 0;
			color: get-color(white);
		}
	}

}
