.key-figures__chart-container {
	.select-date-wrapper {
		position: relative;

		@include breakpoint( small only ) {
			position: static;

			&.end-date {
				.rdp {
					transform: translateX(-50%);
    				left: 50%;
				}
			}
		}
	}
	.date-selected-wrapper {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		color: get-color(darkBlue);
	}

	// React DayPicker
	.rdp {
		display: none;
		position: absolute;
		background: get-color(white);
		box-shadow: 10px 10px 20px 0px rgba(8, 21, 73, 0.10);
		margin-left: 0;
		
		&.calendar-open {
			display: block;
		}

		&-day_selected, 
		&-day_selected:focus-visible, 
		&-day_selected:hover {
			background-color: get-color(darkBlue);
		}

		&-button:hover:not([disabled]):not(.rdp-day_selected) {
			background-color: get-color(warmGray);
		}
	}
}