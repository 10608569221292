.holdings-list {
	h3 {
		margin-bottom: 0.5rem;
	}

	.holdings-table {
		width: 100%;
		margin-bottom: 1.5rem;

		th {
			text-align: left;

			&:first-of-type {
				width: 100px;
			}
		}
	}
}