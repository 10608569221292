.link-list {
	@include resetList;

	&__item {
		border-top: 1px solid get-color(mediumBlue);
		&:last-child {
			border-bottom: 1px solid get-color(mediumBlue);
		}
	}
	&__link {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		color: inherit;
		padding: rem(18) rem(5) rem(18) rem(5);

		will-change: padding, background-color;
		transition: padding 0.2s $ease, background-color linear 0.2s;

		span:not(.arrow) {
			white-space: nowrap;
	    	overflow: hidden;
	    	text-overflow: ellipsis;
		}

		&:hover {
			padding: rem(18) rem(10) rem(18) rem(15);
			// background-color: get-color(warmGray40);			
		}

		@include breakpoint(large) {
			font-size: rem(18);
		}
	}
}
