
html,
body {
	height: 100%;
	min-height: 100%;
}

// normalize next container
#__next {
	position: relative;
	height: 100%;
}

::selection {
	background-color: get-color(darkBlue);
	color: get-color(white);
}

.embed-container {
	position: relative;
	padding-top: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


.site-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;

	&--theme-dark {
		background-color: get-color(darkBlue);
		color: get-color(white);
	}

	.main-content {
		flex: 1;
	}
	#content-pusher {
		height: rem(120);
		@include breakpoint( medium ) {
			height: rem(160);
		}
	}
}


