// Base Typography
html {
	font-size: $global-font-size;
}

body {
	@include standardFontSize;
	font-family: $body-font-family;
	font-weight: $global-weight-normal;
	line-height: $global-lineheight;
	color: get-color(mediumBlue);
}

// Paragraphs
p {
	font-size: inherit;
	font-weight: inherit;
	line-height: $paragraph-lineheight;
	margin: 0 0 $paragraph-margin-bottom;
	font-feature-settings: "kern" 1;

	&.larger-text {
		@include largerFontSize;
	}
}

// Links
a {

	text-decoration: none;

	&:not([class]) {
		color: $anchor-color;
		text-decoration: $anchor-text-decoration;
		line-height: inherit;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $anchor-color-hover;
			@if $anchor-text-decoration-hover != $anchor-text-decoration {
				text-decoration: $anchor-text-decoration-hover;
			}
		}
	}

	img {
		border: 0;
	}
}

strong,
b {
	font-weight: $global-weight-bold;
}

ul, ol {
	font-size: inherit;
	padding-left: 1rem;

	li {
		margin-bottom: 0.5rem;
	}
}


// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	font-style: $header-font-style;
	color: $header-color;
	margin-top: 0;
	margin-bottom: $header-margin-bottom;
	line-height: $header-lineheight;
	font-feature-settings: "kern" 1;

	.site-container--theme-dark & {
		color: $white;
	}

}

h1, h2 {
	letter-spacing: -0.02em;
	font-weight: 500;
}

h1 {
	line-height: 56px;
	
	@include breakpoint( large ) {
		line-height: 80px;
	}
}

h2 {
	@include breakpoint( large ) {
		line-height: 52px;
	}
}

// Heading sizes
@each $size, $headers in $header-sizes {
	@include breakpoint($size) {
		@each $header, $font-size in $headers {
			#{$header} {
				font-size: rem($font-size);
			}
		}
	}
}
