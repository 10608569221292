.event-card {
	+ .event-card {
		@include modulePaddingTop;
	}

	&__wrapper {
		@include secondary-grid-row;
		align-items: flex-start;
		@include breakpoint( medium ) {
			align-items: center;
		}
	}

	&__media {
		@include secondary-grid-column(6);
		@include breakpoint( medium ) {
			@include secondary-grid-column(11);
		}
	}

	&__media-container {
		position: relative;
		padding-top: 100%;

		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__media-placeholder {
		padding-top: 100%;
		background-color: get-color(warmGray);
	}
	&__content {
		@include secondary-grid-column(16);
		margin-left: secondary-grid-column(2);
		@include breakpoint( medium ) {
			@include secondary-grid-column(11);
			// margin-left: secondary-grid-column(2);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(9);
		}
	}

	&__date {
		margin-bottom: rem(25);
		@include largerFontSize;
	}

}
