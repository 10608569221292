.sector-list {
	@include resetList;
	// @include modulePaddingTop;

	.sector-list__item {
		margin-bottom: 0;

		.button {
			@include breakpoint( small only ) {
				white-space: pre-wrap;
			}		
		}
	}
}
