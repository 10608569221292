.slideshow {

	@include modulePaddingTop;
	max-width: 100%;
	overflow: hidden;

	&__wrapper {
		@include secondary-grid-row();
	}
	&__content,
	&__footer {
		@include secondary-grid-column(24);
		@include breakpoint(medium) {
			@include secondary-grid-column(20);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(18);
			margin-left: secondary-grid-column(6);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(16);
		}
	}

	&__footer {
		padding-top: rem(30);
	}
	&__footer-inner {
		display: flex;

		span {
			display: block;
			padding: 0 rem(20);
		}

	}

	&__inner {
		position: relative;
		width: 100%;

		transform: translate3d(0,0,0);
		will-change: transform;
		transition: transform 0.35s $ease;

	}


	&__list {
		@include resetList;
		display: flex;

	}
	&__item {


		flex: 0 0 auto;
		height: 25vh;
		min-height: rem(250);
		position: relative;
		padding-right: rem(30);

		@include breakpoint( large ) {
			height: 60vh;
		}

		.image-container {
			height: 100%;
		}

		img {
			width: 100%;
			max-height: 100%;
		}
	}

}
