.deal-card {
	&__wrapper {
		@include flex-row();
		@include full-padding();
	}

	&__media {
		position: relative;
		@include secondary-grid-column(20);
		@include breakpoint(medium) {
			@include secondary-grid-column(12);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(3);
		}
	}
	&__media-placeholder {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: get-color(warmGray60);
	}
	&__body {
		@include secondary-grid-column(20);
		// margin-left: secondary-grid-column(4);
		@include breakpoint(medium) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(8);
		}

		padding: rem(50) 0;
	}

	&__date {
		display: block;
		margin-bottom: rem(15);
		color: get-color(permafrost);
		opacity: 0.6;
	}
	&__title {
		margin-bottom: rem(15);
	}
	&__price {
		@include h3FontSize;
		color: get-color(permafrost);
	}
}
