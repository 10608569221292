.full-service-navigation {

	@include modulePaddingTop();

	&__wrapper {
		@include secondary-grid-row();
	}

	&__block {
		@include secondary-grid-column(24);
		margin-bottom: rem(50);

		@include breakpoint( medium ) {
			@include secondary-grid-column(12);
			padding-left: secondary-grid-column(1);
			padding-right: secondary-grid-column(1);

			&:nth-child(even) {
				border-left: 1px solid get-color(darkBlue);
			}

		}

		@include breakpoint( large ) {
			// @include secondary-grid-column(12);
			padding-left: secondary-grid-column(2);
			padding-right: secondary-grid-column(2);
		}


	}

	&__block-header {
		margin-bottom: rem(30);

		a {
			color: get-color(rust);
		}
	}
}
