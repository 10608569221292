.block-single-media {

	@include modulePaddingTop;

	&__wrapper {
		// @include row();
		@include secondary-grid-row();
	}
	&__content,
	&__caption {
		@include secondary-grid-column(24);
		img,
		video {
			width: 100%;
		}
	}
	&--size-small &__content,
	&--size-small &__caption {
		@include secondary-grid-column(20);
		margin-left: secondary-grid-column(2);
		@include breakpoint(medium) {
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(6);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(12);
		}
	}
	&--size-medium &__content,
	&--size-medium &__caption {
		@include breakpoint(medium) {
			@include secondary-grid-column(10);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(6);
			padding: 0;
		}
		// @include breakpoint(xlarge) {
		// 	@include secondary-grid-column(9);
		// }
	}

	&--size-insight &__content,
	&--size-insight &__caption {
		@include secondary-grid-column(22);
		// @include breakpoint(medium) {
		// 	@include secondary-grid-column(10);
		// }
		@include breakpoint(large) {
			@include secondary-grid-column(18);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(2);
		}
	}

	&__caption-container {
		@include secondary-grid-column(12);
		padding-top: rem(20);
		@include breakpoint(medium) {
			@include secondary-grid-column(10);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(8);
		}
	}
	&__caption {
		@include smallerFontSize;
		padding-top: rem(20);
	}
}


.block-full-image {

	@include moduleBottomMargin;
	position: relative;
	height: 100vh;

	&__inner {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

	}

}

.news-article {
	.block-single-media__content,
	.block-single-media__caption {
		@include secondary-grid-column(20);
		margin-left: secondary-grid-column(2);
		@include breakpoint(medium) {
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(6);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(12);
		}
	}
}
