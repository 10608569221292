html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
	box-sizing: inherit;
}

// Default body styles
body {
	margin: 0;
	padding: 0;
	color: $body-font-color;
	background: $body-background;
}

figure {
	padding: 0;
	margin: 0;
}

img {
	// Grid defaults to get images and embeds to work properly
	width: 100%;
	height: auto;

	// Get rid of gap under images by making them display: inline-block; by default
	display: inline-block;
	vertical-align: middle;
}


hr {
    background: #ECF0F4;
    border: 0;
    height: 1px;
}


button {
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
}


.button-row {
	@include resetList;
	display: flex;
	flex-wrap: wrap;
	&__item {
		padding-top: rem(15);
		margin-right: rem(15);
		@include breakpoint( large ) {
			padding-top: rem(20);
			margin-right: rem(20);
		}
	}
}


a.button, button.button {

	display: inline-block;
	padding: rem(12) rem(15) rem(10) rem(16);
	border-radius: rem(40);
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	will-change: background-color, color;
	transition: background-color 0.15s linear, color 0.15s linear 0.06s;

	@include breakpoint( large ) {
		padding: rem(14) rem(22) rem(13);
		border-radius: rem(50);
	}

	.arrow {
		margin-left: 0.5rem;
	}

	&--standard {
		border: 1px solid get-color(darkBlue);
		background-color: get-color(darkBlue);
		color: $white;

		&:hover {
			background-color: transparent;
			color: get-color(darkBlue);
		}
	}

	&--inverted {
		border: 1px solid get-color(darkBlue);
		background-color: transparent;
		color: get-color(darkBlue);

		&:hover {
			color: $white;
			background-color: get-color(darkBlue);
		}

		&__white-bg {
			background: get-color(white);
			border: none;
		}
	}

	&--on-dark {
		border: 1px solid get-color(white);
		background-color: transparent;
		color: get-color(white);

		&:hover {
			color: get-color(darkBlue);
			background-color: get-color(white);
		}

	}

}

.back-to-top-button {
	position: fixed;
	right: rem(20);
	bottom: rem(20);
	z-index: 100;

	svg {
		circle {
			transition: opacity 0.3s ease;
		}
	}

	&:hover {
		svg {
			circle {
				opacity: 1;
			}
		}
	}
}

/* 404 page */
.page-not-found {
	@include modulePaddingTop;
	padding-bottom: rem(136);
	background: get-color(darkBlue);

	h1, p {
		color: $white;
	}

	p {
		font-size: rem(20);
		@include breakpoint( medium ) {
			font-size: rem(32);
		}
	}

	a.home-link {
		color: get-color(permafrost);
	}

	.content-container__header-wrapper {
		@include secondary-grid-row;
		margin-bottom: 0;
	}

	.content-container__header-title {
		padding-left: secondary-grid-column(1);
		padding-right: secondary-grid-column(1);

		@include breakpoint( medium ) {
			padding-left: secondary-grid-column(2);
			padding-right: secondary-grid-column(3);
		}
			
	}
}