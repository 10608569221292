.services-overview {

	@include modulePaddingTop;
	@include moduleBottomMargin;

	&__list {
		@include resetList;
	}

	&__list-item {
		&:not(:first-child) {
			@include modulePaddingTop;
		}

		&__header {
			@include row();
			justify-content: center;
		}
		&__header-content {
			@include flex-column(6);
			text-align: center;
		}
	}

	&__inner-list {
		@include resetList;
		padding-top: rem(60);
	}

	&__service-card {
		@include secondary-grid-row();
		align-items: center;

		&__media {
			position: relative;
			@include secondary-grid-column(20);
			@include breakpoint(medium) {
				@include secondary-grid-column(12);
			}
			@include breakpoint(large) {
				@include secondary-grid-column(10);
				margin-left: secondary-grid-column(2);
			}
		}

		&__content {
			@include secondary-grid-column(20);
			margin-left: secondary-grid-column(4);
			padding: rem(50) 0;
			@include breakpoint(medium) {
				@include secondary-grid-column(10);
				margin-left: secondary-grid-column(2);
			}
			@include breakpoint(large) {
				@include secondary-grid-column(8);
			}

		}
	}

	&__inner-list-item:nth-child(even) &__service-card__media {
		order: 2;
	}
}
