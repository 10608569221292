.article-header {

	&__top-media {

		position: relative;
		height: 40vh;

		@include breakpoint( large ) {
			height: 75vh;
		}

		img, video {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

	}

	&__media-wrapper {
		@include row();
		@include modulePaddingTop;
	}
	&__media-container {
		@include flex-column(12);
	}

	&__wrapper {
		@include full-padding;
		@include modulePaddingTop;
		max-width: $global-width;
		margin: 0 auto;
	}
	&__inner {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	&__content {
		@include largerFontSize;
		@include secondary-grid-column(20);
		margin-left: secondary-grid-column(2);
		@include breakpoint(medium) {
			order: 2;
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(14);
			
			p:last-of-type {
				margin: 0;
			}
		}
	}

	&__meta {
		@include smallerFontSize;
		@include breakpoint(small only) {
			@include secondary-grid-column(20);
			margin-left: secondary-grid-column(2);

		}
		@include breakpoint(medium) {
			order: 1;
			@include secondary-grid-column(4);
			padding-top: rem(20);
		}
	}

	&__tag-list {
		@include resetList;
		display: flex;
		flex-wrap: wrap;
	}
	&__tag {
		margin-right: rem(10);
		margin-bottom: rem(10);
	}

	// Offerings page
	&.offerings {
		.article-header__content {
			h2 {
				font-weight: 400;

				@include breakpoint(large) {
					font-size: rem(32);
					line-height: 42px;
				}
			}
		}
	}

	.offerings-dates {
		span {
			display: block;
			margin-bottom: rem(8);
		}
	}

	// Deal page
	&.deal {
		h2 {
			font-weight: 400;

			@include breakpoint(large) {
				font-size: rem(32);
				line-height: 42px;
			}
		}
	}

	// Insight page
	&.insight {
		.article-header {
			&__media-wrapper {
				padding-top: rem(0);
			}
			&__tag {
				margin-bottom: rem(20);
				.tag-pill {
					border: 1px solid get-color(warmGray);
				}
			}
			&__label {
				color: get-color(permafrost);
			}
			&__meta {
				p {
					font-size: rem(16);
					margin-bottom: rem(6);
				}
			}
		}
	}
}
