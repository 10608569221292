$cubeHeight: rem(84);

.featured-offering {
	width: 100%;
	background: get-color(darkBlue);

	&__link {
		display: inline-block;
		width: 100%;
		height: $cubeHeight;
		color: get-color(permafrost);
		margin-bottom: 0;

		padding: 1rem 2rem;
		font-size: rem(16);

		@include breakpoint(medium) {
			padding: 1.5rem 2rem;
			font-size: rem(24);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.ticker {
		height: $cubeHeight;
		position: relative;
		overflow: hidden;

		.cube {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
			transform-style: preserve-3d;
			transition: transform 1s;
			transform-origin: center center center;
			
			&.show-front { 
				transform: rotateX(0deg); 
			}

			&.show-back { 
				transform: rotateX(180deg); 
			}

			&.show-top { 
				transform: rotateX(270deg); 
			}

			&.show-bottom { 
				transform: rotateX(90deg); 
			}

		}

		.featured-offering__link {
			// display: block;
			display: flex;
			align-items: center;
			background: get-color(darkBlue);
			position: absolute;
    		width: 100%;
    		height: rem(84);

			&--front {
	  			transform: rotateX(0deg) translateZ($cubeHeight / 2);
	  		}
	  		&--back {
	  			transform: rotateY(180deg) rotateZ(180deg) translateZ($cubeHeight / 2);
	  		}
	  		&--top {
	  			transform: rotateX(90deg) translateZ($cubeHeight / 2);
	  		}
	  		&--bottom {
	  			transform: rotateX(-90deg) translateZ($cubeHeight / 2);
	  		}
		}

	}
}

@keyframes spin {
	from {
    	transform: rotateX(0deg);
  	}
  	to {
    	transform: rotateX(360deg);
  	}
}