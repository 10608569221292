.lock-up-simple {

	position: relative;

	h1, h2, h3, h4, h5, h6 {
		color: inherit;
	}

	&--background-gray {
		background: get-color(warmGray);
		color: get-color(darkBlue);
	}

	&--background-blue {
		background: get-color(darkBlue);
		color: get-color(white);
	}

	&__wrapper {
		position: relative;
		z-index: 2;
		@include secondary-grid-row();
	}
	&__content {
		@include secondary-grid-column(24);
		padding-top: rem(50);
		padding-bottom: rem(70);
		@include breakpoint(medium) {
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(1);
			padding-top: rem(80);
			padding-bottom: rem(110);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(12);
			margin-left: secondary-grid-column(2);
			padding-top: rem(100);
			padding-bottom: rem(140);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(10);
			padding-top: rem(160);
			padding-bottom: rem(200);
		}
	}

	&__cta-list {
		@include resetList;
		padding-top: rem(30);
		display: flex;
		flex-wrap: wrap;
	}
	&__cta-list-item {
		margin-right: rem(20);
		margin-bottom: rem(20);
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		svg {
			// position: absolute;
			// left: 0;
			// bottom: 0;
			// width: 100%;
			// height: auto;
			object-fit: contain;
			object-position: bottom left;
			height: 100%;
		}
	}

}
