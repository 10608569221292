.flex-table {
	&__row {
		@include secondary-grid-row();

		&--header {
			@include breakpoint(medium down) {
				display: none;
			}
		}

		&.deal-link, &.offering-link {
			color: get-color(mediumBlue);
		}

		&.offering-link:last-of-type {
			.flex-table__row-inner {
				border-bottom: none;
			}
		}

	}

	&__row-inner {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: rem(20);
		padding-bottom: rem(20);
		border-bottom: 1px solid get-color(permafrost);

		@include breakpoint(small only) {
			row-gap: 1rem;
		}
	}

	&__row--content &__row-inner {
		padding-top: rem(30);
		padding-bottom: rem(30);
		@include breakpoint(large) {
			padding-top: rem(50);
			padding-bottom: rem(50);
		}
	}

	&__column {
		// Deals table
		&--deals-sector {
			@include secondary-grid-column(3);
			@include breakpoint(medium down) {
				order: 5;
			}

			@include breakpoint(small only) {
				a.button {
					max-width: unset;
				}
			}
		}
		&--deals-company {
			@include breakpoint(medium down) {
				display: none;
			}
			@include breakpoint(large) {
				@include secondary-grid-column(3);
				margin-left: secondary-grid-column(1);
			}
		}
		&--deals-title {
			@include secondary-grid-column(24);
			@include breakpoint(medium down) {
				order: 2;
			}
			@include breakpoint(large) {
				@include secondary-grid-column(8);
				margin-left: secondary-grid-column(1);
			}
		}
		&--deals-value {
			@include secondary-grid-column(24);
			@include breakpoint(medium down) {
				order: 3;
			}
			@include breakpoint(large) {
				@include secondary-grid-column(3);
				margin-left: secondary-grid-column(1);
			}
		}
		&--deals-date {
			@include secondary-grid-column(24);
			@include breakpoint(medium down) {
				order: 1;
			}
			@include breakpoint(large) {
				@include secondary-grid-column(3);
				margin-left: secondary-grid-column(1);
			}
		}

		// Offerings table
		&--offerings-date {
			width: 100%;
		}

		@include breakpoint(medium) {
			&--offerings-sector {
				@include secondary-grid-column(4);
			}
			&--offerings-title {
				@include secondary-grid-column(11);
				margin-left: secondary-grid-column(1);
			}
			&--offerings-date {
				@include secondary-grid-column(3);
				margin-left: secondary-grid-column(1);
			}
			&--offerings-link {
				@include secondary-grid-column(3);
				margin-left: secondary-grid-column(1);
			}
		}

		// Open positions table
		&--open-position-title {
			@include secondary-grid-column(14);
		}
		&--open-position-company {
			@include secondary-grid-column(4);
			margin-left: secondary-grid-column(1);
		}
		&--open-position-location {
			@include secondary-grid-column(4);
			margin-left: secondary-grid-column(1);
		}
	}

	&__row--content &__column--deals-value {
		@include largerFontSize;
	}

	&__row--content &__column--offerings-link {
		text-align: right;
	}

	&__row--content &__column--open-position-link {
		text-align: right;
	}


	// Fix for podcasts list on updates page
	&__row--content__podcasts {
		.flex-table__row-inner {
			// justify-content: space-between;
		}
		.flex-table__column {
			&__podcasts-title {
				@include secondary-grid-column(4);
				// margin-left: secondary-grid-column(1);
			}
			&__podcasts-text {
				@include secondary-grid-column(14);
				margin-left: secondary-grid-column(1);
			}
			&__podcasts-link {
				@include secondary-grid-column(5);
				display: flex;

				a {
					margin-left: auto;
				}
				// margin-left: secondary-grid-column(1);
			}
		}
	}
}
