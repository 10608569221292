//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography

// 1. Global
// ---------

$global-font-size: 100%;
$global-width: rem(1680);
$global-lineheight: 1.5;
$global-radius: 0;
$global-margin: rem(20);
$global-padding: rem(20);

$color-palette: (
	black: #000000,
	white: #FFFFFF,
	darkBlue: #081549,
	mediumBlue: #474D65,
	permafrost: #00E6FF,
	rust: #9C5333,
	warmGray: #EBE8E3,
	warmGray60: #F3F1EE,
	warmGray40: #F7F6F4
);
$black: get-color(black);
$white: get-color(white);

$ease: cubic-bezier(.47,.39,.32,1);
$gradient-blue: linear-gradient(120.5deg, #081549 31.47%, #00E6FF 187.95%);
$gradient-gray: linear-gradient(120.5deg, get-color(warmGray) 31.47%, get-color(warmGray40) 187.95%);

// 2. Breakpoints
// --------------

$breakpoints: (
	small: 0,
	medium: 640px,
	large: 1024px,
	xlarge: 1440px,
	xxlarge: 1680px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$secondary-grid-column-count: 24;
$grid-column-gutter: (
	small: rem(15),
	medium: rem(20),
	large: rem(30),
	xlarge: rem(50),
);
$outer-padding: (
	small: rem(15),
	medium: rem(30),
	large: rem(40),
	xlarge: rem(50),
);

// 4. Base Typography
// ------------------

$font-family-sans: "Formular", Helvetica, Arial, sans-serif;
$font-family-serif: serif;
$font-family-monospace: "Liberation Mono", Courier, monospace;
$global-weight-thin: 200;
$global-weight-light: 300;
$global-weight-normal: 400;
$global-weight-medium: 500;
$global-weight-semi: 600;
$global-weight-bold: 700;
$global-weight-black: 900;

$body-background: $white;
$body-font-color: $black;
$body-font-family: $font-family-sans;

$paragraph-lineheight: $global-lineheight;
$paragraph-margin-bottom: 1.5rem;
$paragraph-text-rendering: optimizeLegibility;

$header-font-family: $font-family-sans;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$header-color: get-color(darkBlue);
$header-lineheight: 1.35;
$header-margin-bottom: 1.5rem;
$header-text-rendering: geometricPrecision;
$header-sizes: (
	small: (
		"h1": 50,
		"h2": 26,
		"h3": 18,
		"h4": 16,
		"h5": 16,
		"h6": 16,
	),
	medium: (
		"h1": 64,
		"h2": 32,
		"h3": 24,
		"h4": 16,
		"h5": 16,
		"h6": 16,
	),
	large: (
		"h1": 72,
		"h2": 48,
		"h3": 32,
		"h4": 24,
		"h5": 24,
		"h6": 24,
	),
);

$anchor-color: get-color(rust);
$anchor-color-hover: inherit;
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
