.research-reports-list {
	@include modulePaddingTop();

	&__wrapper {
		@include secondary-grid-row;
	}
	&__content {
		overflow: auto;
	}

	.research-reports-table {
		table-layout: fixed;
	    white-space: nowrap;
	    min-width: 100%;
		margin-bottom: 1.5rem;

		th {
			text-align: left;
		}

		th, td {
			padding: 0.5rem;
		}
	}
}