.site-footer {
	padding: rem(80) 0 rem(80);
	background-color: get-color(darkBlue);
	color: get-color(white);

	a {
		color: $white;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	p {
		&:last-child {
			margin: 0;
		}
	}

	&__wrapper {
		@include row();
	}
	&__branding {
		@include flex-column(12);
		svg {
			width: 100%;
			height: auto;
			max-width: rem(140);
		}
	}

	&__navigation {
		@include flex-column(12);
	}

	&__copyright {
		@include flex-column(12);
	}

	.footer-navigation {
		padding: rem(100) 0;

		&__list {
			@include resetList();
			@include row();
		}

		&__toplevel-item {
			@include flex-column(6);
			margin-bottom: rem(40);
			@include breakpoint(medium) {
				@include flex-column(4);
			}
			@include breakpoint(large) {
				@include flex-column(3);
			}
		}

		&__toplevel-link {
			@include largerFontSize;
			color: get-color(permafrost);
		}

		&__child-list {
			@include resetList();
			padding-top: rem(20);
		}

		&__child-item {
			margin-bottom: rem(10);
		}

		a {
			text-decoration: none;
		}
	}

	.decor-lines {
		display: flex;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}
