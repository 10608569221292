.management-list {

	@include modulePaddingTop;

	&__heading-wrapper {
		@include row();
	}

	&__heading {
		@include flex-column(12);

		h2 {
			margin-bottom: 0;
		}
	}

	&__item {
		&:not(:first-child) {
			@include modulePaddingTop;
		}
	}

	&__item-inner {
		@include secondary-grid-row;
		align-items: center;
	}

	&__item-media {
		position: relative;
		@include breakpoint( small only ) {
			margin-bottom: rem(40);
		}
	}

	&__item--image-align-left &__item-media {
		@include secondary-grid-column(20);
		@include breakpoint(medium) {
			@include secondary-grid-column(12);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(2);
		}
	}

	&__item--image-align-right &__item-media {
		@include secondary-grid-column(20);
		@include breakpoint(medium) {
			order: 2;
			@include secondary-grid-column(12);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(10);
		}
	}

	&__item-media-container {
		// height: 35vh;

		img {
			width: 100%;
		}

		// @include breakpoint(medium) {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 100%;

		// 	img {
		// 		object-fit: cover;
		// 		width: 100%;
		// 		height: 100%;
		// 	}
		// }
	}

	&__item-media-placeholder {
		position: relative;
		padding-top: 75%;
	}

	&__item-media-placeholder-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: get-color(warmGray60);
	}

	&__item-content {
		@include secondary-grid-column(20);
		// margin-left: secondary-grid-column(4);
		@include breakpoint(medium) {
			@include secondary-grid-column(10);
			padding: rem(50) 0;
		}
		@include breakpoint(large) {
			@include secondary-grid-column(8);
			padding: rem(80) 0;
		}

		h3 {
			margin: 0;
		}

	}

	&__item--image-align-left &__item-content {
		@include breakpoint(medium) {
			margin-left: secondary-grid-column(2);
		}
	}
	&__item--image-align-right &__item-content {
		@include breakpoint(medium) {
			margin-left: 0;
		}
		@include breakpoint(large) {
			margin-left: secondary-grid-column(2);
		}
	}

	&__contact-list {
		@include resetList;
	}
	&__contact-list-item {
		display: flex;
		&:not(:last-child) {
			margin-bottom: rem(10);
		}
	}
	&__contact-list-label {
		flex: 0 0 rem(80);
	}

}
