.section-header {

	@include modulePaddingTop;
	
	&--sectors {
		@include modulePaddingBottom;
	}

	&__wrapper {
		@include flex-row();
		@include full-padding();
		justify-content: center;
	}
	&__container {
		@include secondary-grid-column(22);
		text-align: center;

		@include breakpoint( medium ) {
			@include secondary-grid-column(18);
		}
		@include breakpoint( large ) {
			@include secondary-grid-column(12);
		}

	}

	&__links-list {
		@include resetList;
		padding-top: rem(30);
	}
	&__links-item {
		padding: 0 rem(20) rem(20);
	}

}
