.news-card {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-top: 1px solid get-color(permafrost);
	padding-top: rem(30);

	&__date {
		@include smallerFontSize;
		display: block;
		margin: 0 0 rem(8);
		color: get-color(mediumBlue);
		opacity: 0.6;
	}
	&__title {
		margin: 0 0 rem(15);

		a {
			color: get-color(darkBlue);
		}
	}
	&__body {
		color: get-color(mediumBlue);
	}

	.button-container {
		margin-top: auto;
	}
}

