.toggle-button {
	display: flex;
	align-items: center;

	span {
		display: block;
	}

	&__label {
		padding-right: rem(6);
	}

	&--active &__icon {
		transform: rotate(180deg);
	}
}
