.mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: get-color(darkBlue);
	z-index: 10;

	color: $white;

	&__navigation-container {
		// @include flex-column(24);
		// padding: rem(100) rem(30);
		@include outer-padding();
		padding-top: rem(100);
		padding-bottom: rem(100);

		overflow-y: auto;
    	height: 100%;

		ul {
			@include resetList;
			
		}

		.top-level-list {
			padding: 0 rem(7.5);
			
			> li {
				border-top: 1px solid get-color(permafrost);
				color: get-color(permafrost);
				// font-weight: 600;
				padding: rem(20) 0;
			}
		}

		li {
			// margin-bottom: rem(30);
			padding: rem(15) 0;
			color: $white;
		}

		a {
			color: inherit;
			text-decoration: none;

			&.arrow-link {
				display: flex;
				justify-content: space-between;
				padding-right: 0.75rem;
			}
		}

		button {
			color: inherit;
		}

		.toggle-button {
			// color: get-color(permafrost);
			width: 100%;
			justify-content: space-between;
			padding-right: rem(12);
		}

		.login-button {
			display: inline-flex;
			.link-button__label {
				padding-top: 0;
			}
		}
	}

	.sub-navigation {
		.link__label {
			margin-right: rem(8);
		}

		&__accordion {

			&--closed {
				overflow: hidden; 
				max-height: 0px;
	    	}

			&--expanded {
				margin-top: rem(15);
            	max-height: 999em;
        	}
		}

		&--level-3 {
			padding-left: rem(10);
		}
	}
}