.lock-up {

	@include modulePaddingTop;

	&__wrapper {
		@include secondary-grid-row;
	}

	&__media {
		position: relative;
		@include breakpoint( small only ) {
			margin-bottom: rem(40);
		}

		&-embed-container {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;

			.embed-wrapper {
				width: 100%;
			}
		}
	}

	&--image-align-left &__media {
		@include secondary-grid-column(20);
		@include breakpoint(medium) {
			@include secondary-grid-column(12);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(2);
		}
	}

	&--image-align-right &__media {
		@include secondary-grid-column(20);
		@include breakpoint(medium) {
			order: 2;
			@include secondary-grid-column(12);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(10);
		}
	}

	&__media-container {
		// height: 35vh;

		img, video {
			width: 100%;
		}

		@include breakpoint(medium) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img, video {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__media-placeholder {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: get-color(warmGray60);
	}

	&__content {
		@include secondary-grid-column(24); // 20
		// margin-left: secondary-grid-column(4);
		@include breakpoint(medium) {
			padding: rem(50) 0;
		}
		@include breakpoint(large) {
			padding: rem(80) 0;
		}

	}

	&--image-align-right &__content {
		@include breakpoint(medium) {
			@include secondary-grid-column(10);
			margin-left: 0;
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(8);
			margin-left: secondary-grid-column(2);
		}
	}

	&--image-align-left &__content {
		@include breakpoint(medium) {
			@include secondary-grid-column(10);
			margin-left: secondary-grid-column(2);
		}
		@include breakpoint(xlarge) {
			@include secondary-grid-column(8);
			margin-left: secondary-grid-column(2);
		}
	}

	&--reverse &__media {
		order: 2;
	}

	&__content-header {
		p:last-child {
			margin: 0;
		}
	}

	&__links-list {
		@include resetList;
		padding-top: rem(30);
	}

	&__content-block {

		&:not(:first-child) {
			padding-top: rem(30);
			@include breakpoint(medium) {
				padding-top: rem(40);
			}
			@include breakpoint(large) {
				padding-top: rem(50);
			}
		}

		h2, h3 {
			margin: 0 0 rem(20);
		}

		p {
			&:last-child {
				margin: 0;
			}
		}

	}

	&__content-link {
		padding-top: rem(20);
		@include breakpoint( medium ) {
			padding-top: rem(25);
		}
		@include breakpoint( large ) {
			padding-top: rem(30);
		}
	}

}
