.body {

	@include modulePaddingTop;

	&__wrapper {
		@include secondary-grid-row;
	}
	&__content {
		@include secondary-grid-column(20);
		margin-left: secondary-grid-column(2);
		@include breakpoint(medium) {
			@include secondary-grid-column(16);
			margin-left: secondary-grid-column(6);
		}
		@include breakpoint(large) {
			@include secondary-grid-column(12);
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@include largerFontSize;
		margin: 0 0 rem(10);
		&:not(:first-child) {
			margin-top: rem(28);
		}
	}

	p {
		&:last-child {
			margin: 0;
		}
	}

}
